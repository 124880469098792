<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :width="500"
    :persistent="loading"
  >
    <v-card tile shaped>
      <v-card-title
        class="font-weight-semibold"
        :class="`${titleColor}--text`"
        >{{ title }}</v-card-title
      >
      <v-divider class="my-0" />
      <v-card-text class="py-4">
        <div class="text-body-1 text-pre-wrap">{{ message }}</div>
      </v-card-text>
      <v-card-actions class="d-flex py-6 justify-end" style="gap: 10px">
        <v-btn
          v-if="!hideCancelButton"
          :disabled="loading"
          outlined
          large
          :min-width="100"
          color="primary"
          @click="$emit('cancel')"
          >{{ $t('common.cancel') }}</v-btn
        >
        <v-btn
          color="primary"
          @click="$emit('done')"
          large
          dark
          :min-width="100"
          :loading="loading"
          >{{ doneButtonTitle ?? $t('common.close') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CzPromptDialog',
  props: {
    title: {
      type: String
    },
    titleColor: {
      type: String,
      default: 'textPrimary'
    },
    message: {
      type: String
    },
    doneButtonTitle: {
      type: String
    },
    loading: {
      type: Boolean
    },
    hideCancelButton: {
      type: Boolean,
      default: false
    },
    // can be info, success, warning, error
    state: {
      type: String,
      default: 'info'
    }
  }
};
</script>

<style lang="scss" scoped></style>
