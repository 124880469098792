<template>
  <cz-autocomplete
    v-on="$listeners"
    v-bind="$attrs"
    :value="tags"
    :items="items"
    multiple
    @change="onTagsChange"
    :counter="maxTags"
    :item-color="itemColor"
    :error-messages="errorMessages"
    :outlined="outlined && !readonly"
    :placeholder="placeholder"
    :readonly="readonly"
    :hint="hint"
    :solo="readonly"
    :flat="readonly"
    class="pa-0 text-subtitle-2 font-weight-regular"
    :item-text="itemText"
    :item-value="itemValue"
    :combobox="allowEnterNewValues"
  >
    <template v-slot:selection="{ item, index }">
      <cz-chip
        :text="itemText ? item[itemText] : item"
        :removeable="removeable && !readonly"
        :color="color"
        dark
        :text-color="textColor"
        circleEdges
        :small="small"
        :large="large"
        @click:close="onTagRemoved(index)"
      />
    </template>
    <template v-slot:item="{ active, item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <v-list-item-action>
          <cz-checkbox :input-value="active" :color="itemColor" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="primary--text font-weight-semibold">
            {{ itemText ? item[itemText] : item }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </cz-autocomplete>
</template>

<script>
import CzChip from './CzChip.vue';
import CzCheckbox from './CzCheckbox.vue';
import CzAutocomplete from './CzAutocomplete.vue';
export default {
  name: 'CzInputTags',
  components: {
    CzChip,
    CzCheckbox,
    CzAutocomplete
  },
  model: {
    prop: 'tags'
  },
  props: {
    tags: {
      type: Array,
      required: true
    },
    items: {
      type: Array
    },
    maxTags: {
      type: Number,
      default: 0
    },
    removeable: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'textPrimary'
    },
    textColor: {
      type: String,
      default: 'white'
    },
    errorMessages: {
      type: [String, Array]
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String
    },
    itemText: {
      type: String
    },
    itemValue: {
      type: String
    },
    itemColor: {
      type: String
    },
    // if the use hashtags flat is true then
    // the tags that will be entered by the user must be of hashtags form
    // they must start with # and must contain only numbers and characters
    useHashtags: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    allowEnterNewValues: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    }
  },
  methods: {
    onTagsChange(value) {
      if (this.useHashtags) {
        // check that the tags array contains only hashtags
        // remove all which are not hashtag
        const tags = value.filter((tag) => {
          return tag.match(/#[a-z0-9]+$/i);
        });
        this.$emit('input', tags);
      } else {
        this.$emit('input', value);
      }
    },
    onTagRemoved(index) {
      const _tags = [...this.tags];
      _tags.splice(index, 1);
      this.$emit('input', _tags);
    }
  }
};
</script>

<style lang="scss" scoped></style>
