import { getField, updateField } from 'vuex-map-fields';
import {
  loginWithEmailAndPassword,
  createUser,
  reauth,
  logout
} from '@/core/api';
import defineAbilitiesForUser from '@/shared/services/rbac/rbac.service';

const state = {
  currentUser: null,
  account: null,
  accountId: null,
  abilities: null
};

const getters = {
  isOrderManualCreationActive(state) {
    return state.account?.activeFeatures?.includes('order_creation');
  },
  isOrderItemsActive(state) {
    return state.account?.activeFeatures?.includes('order_creation_with_items');
  },
  getField
};

const mutations = {
  updateField
};

const actions = {
  async logout({ commit }) {
    await logout();

    commit('updateField', {
      path: 'currentUser',
      value: null
    });

    commit('updateField', {
      path: 'account',
      value: null
    });

    commit('updateField', {
      path: 'accountId',
      value: null
    });

    commit('updateField', {
      path: 'abilities',
      value: null
    });
  },
  async login({ commit }, { email, password }) {
    // login user
    const { user } = await loginWithEmailAndPassword(email, password);
    // update current user in store
    commit('updateField', {
      path: 'currentUser',
      value: user
    });

    const abilities = defineAbilitiesForUser(user);

    commit('updateField', {
      path: 'abilities',
      value: abilities
    });

    let account = null;
    // extract account from user and update the state
    // we assume that every user will have at least 1 account
    // currently the app supports one account per user
    if (user?.accounts?.length) {
      account = user.accounts[0];
      commit('updateField', {
        path: 'account',
        value: user.accounts[0]
      });

      commit('updateField', {
        path: 'accountId',
        value: user.accounts[0]._id
      });
    }

    return {
      user,
      account,
      abilities
    };
  },

  async signUp({ commit, dispatch }, user) {
    // signup user
    await createUser(user);
    // after signup perform login (to extract the token)
    await dispatch('login', user);
  },

  async authenticate({ state, commit }) {
    if (state.currentUser) {
      return {
        user: state.currentUser,
        abilities: state.abilities,
        account: state.account
      };
    }

    try {
      const { user } = await reauth();
      // update current user in store
      commit('updateField', {
        path: 'currentUser',
        value: user
      });

      const abilities = defineAbilitiesForUser(user);

      commit('updateField', {
        path: 'abilities',
        value: abilities
      });
      let account = null;
      // extract account from user and update the state
      // we assume that every user will have at least 1 account
      // currently the app supports one account per user
      if (user?.accounts?.length) {
        account = user.accounts[0];
        commit('updateField', {
          path: 'account',
          value: user.accounts[0]
        });

        commit('updateField', {
          path: 'accountId',
          value: user.accounts[0]._id
        });
      }

      return { user, account, abilities };
    } catch (error) {
      if (error.code === 404) {
        await logout();
      }
      return {
        user: null,
        account: null,
        abilities: null
      };
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
